import bottomCate from "./bottomCate.vue";
import Vue from 'vue' 
import loadBtn from "./loadBtn.vue";
import HeadLeft from "./headLeft.vue";
import HeadRight from "./headRight.vue";
import AdComponent from "./AdComponent.vue";
Vue.component('headLeft', HeadLeft)
Vue.component('headRight', HeadRight)
Vue.component('bottomCate', bottomCate)
Vue.component('loadBtn', loadBtn)
Vue.component('Ad', AdComponent)