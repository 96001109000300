<template>
	<div class="footer flex justify-center items-center fc">
		<div class="top">
			<a href="/about">About</a>
		<span class="mx-2">|</span>
		<a href="/privacy">Privacy Policy</a>
		<span class="mx-2">|</span>
		<a href="/contact">Contact</a>
		</div>
		<div class="bottom">
			<div class="cp">Copyright © 2024 Joyit. All Rights Reserved.</div>
		</div>

	</div>

</template>

<script>
export default {
	name: 'Bottom',
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.footer {
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	text-align: center;
	margin : rem(80) rem(-30) 0;
	a,
	span {
		text-decoration: underline;
		color: #042c50;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		text-decoration-line: underline;
	}
	span {
		margin: 0 rem(25);
	}
}
.footer::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: -1px;
    left: 0;
    height: rem(40);
    background: #fff;
    -webkit-clip-path: polygon(0 100%,30% 0,36% 100%,100% 0,100% 100%);
    clip-path: polygon(0 100%,30% 0,36% 100%,100% 0,100% 100%);
}
.cp {
	text-align: center;
	color: #7a7a7a;
	font-family: Roboto;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
}
@media screen and (orientation: portrait) {
	.cp {
		font-size: 11px !important;
	}
}

</style>