// src/mixins/dataMixin.js
import { mapState } from "vuex"
export default {
	computed: {
		...mapState(['adsensConfig']),
		isPC () {
			return this.$store.state.deviceType === 'desktop'
		}
	}
}
